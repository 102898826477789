@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

html, body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    background-color: #ffffff;
    color: #333333;
    line-height: 1.6;
    direction: rtl;  
}

.header {
    width: 100%;
    padding: 20px 0;
    text-align: center;
}

.logo {
    width: 80px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 10px;
}

.header-title {
    font-size: 28px;
    color: #555;
    margin-bottom: 20px;
}
.header-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-divider {
    width: 150px;
    height: 2px;
    background-color: #333;
    margin: 10px auto;
}

nav {
    display: flex;
    justify-content: center;
    gap: 10px;
}

nav a {
    color: #666;
    text-decoration: none;
    font-size: 24px;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 10px;
    transition: color 0.3s, background-color 0.3s; 
}

.welcome {
    padding: 20px;
    text-align: center;
    border-radius: 12px;
    margin: 10px auto;
    max-width: 900px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
    background: #ffffff;
}

.welcome h2 {
    color: #333;
    font-size: 32px;
    font-weight: bold;
}

.welcome p {
    color: #666;
    font-size: 18px;
    line-height: 1.8;
    padding: 5px;
    border-radius: 8px;
}

.services {
    padding: 20px;
    text-align: center;
    border-radius: 12px;
    margin: 10px auto;
    max-width: 900px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.services h2 {
    color: #333;
    font-size: 32px;
    font-weight: bold;
}

.services ul {
    list-style: none;
    padding: 0;
}

.services li {
    background: #f2f2f2;
    color: #666;
    font-size: 18px;
    line-height: 1.8;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.services li:hover {
  color: #101820FF;
  background: #f2aa4cff;  
  transform: scale(1.05);  
}

.smart-locks {
    padding: 20px;
    text-align: center;
    max-width: 900px;
    margin: 10px auto;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.smart-locks h2, .lock-card h3 {
    color: #333;
    font-size: 22px;
}

.lock-card {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.lock-card p {
    color: #666;
    font-size: 16px;
    line-height: 1.8;
}

.lock-image {
    width: 50%;
    border-radius: 8px;
    margin-bottom: 15px;
}

.contact {
    padding: 20px;
    text-align: center;
    max-width: 900px;
    margin: 10px auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px; 
}

.contact p {
    font-size: 16px;
    color: #666;
    line-height: 1.8;
    padding: 10px;
    margin-bottom: 10px; 
}

.social-links {
    margin-top: 20px; 
}

.social-links a {
    color: #666;
    font-size: 24px;
    margin: 0 10px; 
    transition: color 0.3s;
}

.social-links a:hover {
    color: #101820FF; 
}

.button, a.button {
    background-color: #F2AA4CFF;
    color: #101820FF;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover, a.button:hover {
    background-color: #DAA520;
}

@media (min-width: 769px) {
    nav a:hover {
        color: #ffffff;
        background-color: #f2aa4cff; 
    }
}

@media (max-width: 768px) {
    nav {
        flex-direction: column;
        gap: 5px;
    }

    .logo, .header-title {
        margin-bottom: 10px;
    }

    .header-title {
        font-size: 24px;
    }

    nav a {
        padding: 8px;
    }

    .welcome h2, .services h2, .smart-locks h2, .lock-card h3, .contact h2 {
        font-size: 26px;
        margin-bottom: 10px;
    }

    .welcome p, .services li, .lock-card p, .contact p {
        font-size: 14px;
        margin-bottom: 10px;
    }
}
